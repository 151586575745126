<template>
  <div>
    <header-slot />
    <filter-slot
      :filter="[]"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :filter-principal="FiltersForSlot.filterPrincipal"
      @reload="$refs['claimsTable'].refresh()"
    >
      <b-table
        ref="claimsTable"
        slot="table"
        class="blue-scrollbar"
        :items="myProvider"
        :fields="fields"
        :busy="isBusy"
        primary-key="id"
        responsive="md"
        sticky-header="50vh"
        show-empty
        no-provider-filtering
        :per-page="FiltersForSlot.paginate.perPage"
        :current-page="FiltersForSlot.paginate.currentPage"
      >
        <template #cell(nickname)="{ item }">
          <router-link
            :class="textLink"
            :to="`/socialnetwork/leads/new/dashboard/${item.lead_id}`"
            target="_blank"
          >
            {{ item.nickname ? item.nickname : item.lead_name }}
          </router-link>
          <br />
          <span>{{ item.lead_name }}</span>
        </template>

        <template #cell(reshedule_date)="{ item }">
          {{ item.reshedule_date | myGlobalDay }}
        </template>

        <template #cell(user_name)="{ item }">
          {{ item.user_name }}
          <br />
          {{ item.last_contact | myGlobalDay }}
        </template>

        <template #cell(count_reschedule)="{ item }">
          <b-badge
            variant="light-primary"
            class="rounded-circle clickable"
            @click="openModalEventTracking(item.event_id)"
          >
            <feather-icon icon="ListIcon" />
          </b-badge>
        </template>
      </b-table>
    </filter-slot>
    <b-modal
      id="modal-event-tracking"
      v-model="showModalEventTracking"
      title="Event Tracking"
      title-class="h2 text-white"
      centered
      size="xmd"
      hide-footer
      @hide="showModalEventTracking = false"
    >
      <modal-event-tracking :event="event" />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import NoAnswerService from "@/views/social-network/views/no-answer/no-answer.service.js";
import ModalEventTracking from "@/views/crm/views/Lead/lead-event/ModalEventTracking.vue";
export default {
  components: {
    ModalEventTracking,
  },
  data() {
    return {
      fields: [
        {
          key: "nickname",
          label: "Lead Name",
        },
        {
          key: "mobile",
          label: "Mobile",
        },
        {
          key: "owner_name",
          label: "Owner",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "user_name",
          label: "Last Contact Attempt By",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "reshedule_date",
          label: "Reschedule Date",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "count_reschedule",
          label: "Reschedule",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
      showModalEventTracking: false,
      event: {},
    };
  },
  props: {
    tabSelected: String,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  methods: {
    ...mapActions({
      A_GET_EVENT: "CrmEventStore/A_GET_EVENT",
    }),
    async myProvider(ctx) {
      let params = {
        per_page: ctx.perPage,
        page: ctx.currentPage,
        search_text: this.FiltersForSlot.filterPrincipal.model,
      };
      const data = await NoAnswerService.getNoAnswers(params);
      this.FiltersForSlot.totalRows = data.total;
      this.FiltersForSlot.paginate.currentPage = data.current_page;
      this.FiltersForSlot.startPage = data.from;
      this.FiltersForSlot.toPage = data.to;
      return data?.data || [];
    },
    async openModalEventTracking(eventId) {
      const response = await this.A_GET_EVENT({ id: eventId });
      if (response.data[0]) {
        this.event = response.data[0];
        this.showModalEventTracking = true;
      }
    },
  },
};
</script>