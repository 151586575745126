var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-slot'),_c('filter-slot',{attrs:{"filter":[],"total-rows":_vm.FiltersForSlot.totalRows,"paginate":_vm.FiltersForSlot.paginate,"start-page":_vm.FiltersForSlot.startPage,"to-page":_vm.FiltersForSlot.toPage,"filter-principal":_vm.FiltersForSlot.filterPrincipal},on:{"reload":function($event){return _vm.$refs['claimsTable'].refresh()}}},[_c('b-table',{ref:"claimsTable",staticClass:"blue-scrollbar",attrs:{"slot":"table","items":_vm.myProvider,"fields":_vm.fields,"busy":_vm.isBusy,"primary-key":"id","responsive":"md","sticky-header":"50vh","show-empty":"","no-provider-filtering":"","per-page":_vm.FiltersForSlot.paginate.perPage,"current-page":_vm.FiltersForSlot.paginate.currentPage},slot:"table",scopedSlots:_vm._u([{key:"cell(nickname)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{class:_vm.textLink,attrs:{"to":("/socialnetwork/leads/new/dashboard/" + (item.lead_id)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.nickname ? item.nickname : item.lead_name)+" ")]),_c('br'),_c('span',[_vm._v(_vm._s(item.lead_name))])]}},{key:"cell(reshedule_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.reshedule_date))+" ")]}},{key:"cell(user_name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.last_contact))+" ")]}},{key:"cell(count_reschedule)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"rounded-circle clickable",attrs:{"variant":"light-primary"},on:{"click":function($event){return _vm.openModalEventTracking(item.event_id)}}},[_c('feather-icon',{attrs:{"icon":"ListIcon"}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":"modal-event-tracking","title":"Event Tracking","title-class":"h2 text-white","centered":"","size":"xmd","hide-footer":""},on:{"hide":function($event){_vm.showModalEventTracking = false}},model:{value:(_vm.showModalEventTracking),callback:function ($$v) {_vm.showModalEventTracking=$$v},expression:"showModalEventTracking"}},[_c('modal-event-tracking',{attrs:{"event":_vm.event}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }