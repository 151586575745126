import { amgApi } from "@/service/axios";
class NoAnswerService {
  async getNoAnswers(body) {
    try {
      const { data } = await amgApi.post(
        "social-network/no-answer/index",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getUserModule:", error);
      throw error;
    }
  }
}

export default new NoAnswerService();
